import React from 'react';

const Learn = () => {
  return <div className="container pt-5">
            <div className="row">
                <div className="col-md-12 mb-5 text-center">
                    <a href="/" className="coin btn ">
                        Back to homepage
                    </a>
                </div>
                <div className="col-md-6">
                    <h3 className="text-center mb-5">
                        Core strategy concepts
                    </h3>
                    <p className="mt-5">If you want to stay ahead of the market price, learn about the following concepts:</p>
                    <ol>
                        <li>Bullish and bearish divergences</li>
                        <li>Margin pressure levels</li>
                        <li>Bollinger bands</li>
                        <li>Ichimoku Cloud</li>
                        <li>Relative Strength Index</li>
                        <li>Bitcoin sentiment – Bull & Bear Index</li>
                    </ol>
                    And most important, with these concepts <strong>build a strategy and stick to it</strong>!
                    
                </div>
                <div className="col-md-6">
                <h3 className="text-center">Useful links</h3>
                    <ul className="text-start d-inline-block mt-5">
                        <li><a target="_blank" rel="noreferrer" href="https://www.lookintobitcoin.com/charts/stock-to-flow-model/">Stock to flow chart - BTC</a></li>
                        <li><a target="_blank" rel="noreferrer" href="https://moneroj.net/sfmodel/">Stock to flow chart - XMR</a></li>
                        <li><a target="_blank" rel="noreferrer" href="https://www.youtube.com/c/CoinBureau">CoinBureau</a></li>
                        <li><a target="_blank" rel="noreferrer" href="https://www.youtube.com/@intothecryptoverse">IntoTheCryptoVerse </a></li>
                        <li><a target="_blank" rel="noreferrer" href="https://www.lookintobitcoin.com/">Look Into Bitcoin</a></li>
                        <li><a target="_blank" rel="noreferrer" href="https://www.augmento.ai/bitcoin-sentiment/">Bitcoin social sentiment</a></li>
                        <li><a target="_blank" rel="noreferrer" href="https://checkonchain.com/btconchain/feargreed_pricing_usd/feargreed_pricing_usd_light.html">Bitcoin Fear vs Greed index</a></li>
                        <li><a target="_blank" rel="noreferrer" href="https://money.cnn.com/data/fear-and-greed/?iid=EL">CNN - Fear & Greed Index </a></li>
                        <li><a target="_blank" rel="noreferrer" href="https://www.youtube.com/@DigitalAssetNews">Digital Asset News </a></li>
                        {/* <li><a target="_blank" rel="noreferrer" href="https://www.youtube.com/c/CryptoStackers">Margin pressure levels - CryptoStackers</a></li> */}
                        {/* <li><a target="_blank" rel="noreferrer" href="https://app.tokenmetrics.com/bitcoin">Ian Balina - token metrics - BTC</a></li> */}
                        {/* <li><a target="_blank" rel="noreferrer" href="https://www.twitch.tv/doctor_degen">Doctor_Degen - crypto news and tips</a></li> */}
                        <li><a target="_blank" rel="noreferrer" href="https://www.tradingview.com/chart/67OX8UPe/">BTC Chart - RSI, candles, weighted average price</a></li>
                        <li><a target="_blank" rel="noreferrer" href="https://www.tradingview.com/chart/xYIa04Dn/">Current BTC trends chart</a></li>
                        <li><a target="_blank" rel="noreferrer" href="https://academy.binance.com">Binance academy</a></li>
                        <li><a target="_blank" rel="noreferrer" href="https://coinmarketcap.com/">CoinMarketCap - details about coins</a></li>
                        <li><a target="_blank" rel="noreferrer" href="https://www.coingecko.com/en">CoinGecko - details about coins</a></li>
                        <li><a target="_blank" rel="noreferrer" href="https://accounts.binance.com/en/register?ref=ZLTY021Z">Binance referral, 10% off your commission</a></li>
                        {/* <li><a target="_blank" rel="noreferrer" href="https://ftx.com/#a=31866437">FTX referral, 5% off your commission</a></li> */}
                        <li><a target="_blank" rel="noreferrer" href="https://raydium.io/">Use Raydium for quick solana trades</a></li>
                        <li><a target="_blank" rel="noreferrer" href="https://phantom.app/">User friendly wallet for solana - Phantom, only for chrome browser, don't download any other phantom app</a></li>
                        <li><a target="_blank" rel="noreferrer" href="https://www.exodus.com/">Exodus Bitcoin & Crypto Wallet</a></li>
                    </ul>
                </div>
                <div className="col-md-12 mt-5 text-center">
                    <a href="/" className="coin btn">
                        Back to homepage
                    </a>
                </div>
            </div>
        </div>;
};

export default Learn;
