import {

    BrowserRouter,
    Route,
    Routes
    
  } from "react-router-dom";
    
import React from 'react'
    
import App from './App';
import Learn from './Learn';
    
// import NotFound from './NotFound';
    
// import SingleProduct from './SingleProduct';
    

    
class RoutedSite extends React.Component{
    
   render(){
    
    return (
    
        <BrowserRouter>
    
            <Routes>
    
                <Route exact path="/" element={<App/>}></Route>
                <Route exact path="/learn" element={<Learn/>}></Route>
    
                {/* <Route path="/product/:slug" component={ SingleProduct }></Route> */}
    
                {/* <Route component={ NotFound } ></Route> */}
    
            </Routes>
    
        </BrowserRouter>
    
        )
    
   }
    
}
    

    
export default RoutedSite