const allCoins = [
    {
        coin_name:"Solana",
        coin_short:"SOL",
        link:"https://coinmarketcap.com/currencies/solana/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png",
        coin_position:"3",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:"",
        // predictions:{
        //     "2022":"615",
        //     "2023":"833",
        //     "2024":"999",
        //     "2025":"1006",
        //     "2026":"1017",
        //     "2027":"1385",
        //     "2028":"1717",
        //     "2029":"2129",
        //     "2030":"2640",
        // },
        // bb:"90"
    },
    // {
    //     coin_name:"Terra",
    //     coin_short:"LUNA",
    //     link:"https://coinmarketcap.com/currencies/terra-luna/",
    //     coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/4172.png",
    //     coin_position:"16",
    //     pairs:"BUSD",
    //     markets:1,
    //     week:"",
    //     date:"",
    //     predictions:{
    //         "2022":"135.45",
    //         "2023":"217.21",
    //         "2024":"274.45",
    //         "2025":"376.76",
    //         "2026":"438.12"
    //     },
    //      bb:"30"
    // },
    {
        coin_name:"Polygon",
        coin_short:"MATIC",
        link:"https://coinmarketcap.com/currencies/polygon/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png",
        coin_position:"1",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:"",
        // bb:"1"
    },
    {
        coin_name:"Chainlink",
        coin_short:"LINK",
        link:"https://coinmarketcap.com/currencies/chainlink/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/1975.png",
        coin_position:"2",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:"",
        // predictions:{
        //     "2022":"170",
        //     "2023":"175",
        //     "2024":"220",
        //     "2025":"325",
        //     "2026":"349",
        //     "2027":"467",
        //     "2028":"624",
        //     "2029":"839",
        //     "2030":"1122",
        // },
        //  bb:"12.40"
    },
    {
        coin_name:"NEAR Protocol",
        coin_short:"NEAR",
        link:"https://coinmarketcap.com/currencies/near-protocol/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/6535.png",
        coin_position:"11",
        pairs:"USDT",
        markets:1,
        week:"",
        date:"",
        // bb:"18"
    }, 
    {
        coin_name:"Aave",
        coin_short:"AAVE",
        link:"https://coinmarketcap.com/currencies/aave/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/7278.png",
        coin_position:"11",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:"",
        // bb:"18"
    },
    {
        coin_name:"Avalanche",
        coin_short:"AVAX",
        link:"https://coinmarketcap.com/currencies/avalanche/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png",
        coin_position:"11",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:"",
        // predictions:{
        //     "2022":"114.67",
        //     "2023":"147.18",
        //     "2024":"177.59",
        //     "2025":"216.40",
        //     "2026":"283.51"
        // },
        //  bb:"45"
    },
    {
        coin_name:"Cardano",
        coin_short:"ADA",
        link:"https://coinmarketcap.com/currencies/cardano/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/2010.png",
        coin_position:"4",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:""
    },
    {
        coin_name:"Algorand",
        coin_short:"ALGO",
        link:"https://coinmarketcap.com/currencies/algorand/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/4030.png",
        coin_position:"8",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:"",
        // bb:"0.85"
    },
    {
        coin_name:"Polkadot",
        coin_short:"DOT",
        link:"https://coinmarketcap.com/currencies/polkadot-new/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/6636.png",
        coin_position:"18",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:"",
        // bb:"18"
    },
    {
        coin_name:"Arbitrum",
        coin_short:"ARB",
        link:"https://coinmarketcap.com/currencies/arbitrum/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/11841.png",
        coin_position:"18",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:"",
        // bb:"18"
    },
    {
        coin_name:"Optimism",
        coin_short:"OP",
        link:"https://coinmarketcap.com/currencies/optimism-ethereum/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/11840.png",
        coin_position:"18",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:"",
        // bb:"18"
    },
    {
        coin_name:"Arweave",
        coin_short:"AR",
        link:"https://coinmarketcap.com/currencies/arweave/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/5632.png",
        coin_position:"11",
        pairs:"USDT",
        markets:1,
        week:"",
        date:"",
        // bb:"18"
    }, 
    {
        coin_name:"Elrond",
        coin_short:"EGLD",
        link:"https://coinmarketcap.com/currencies/elrond-egld/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/6892.png",
        coin_position:"19",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:"",
        // bb:"130"
    }, {
        coin_name:"TRON",
        coin_short:"TRX",
        link:"https://coinmarketcap.com/currencies/tron/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/1958.png",
        coin_position:"19",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:"",
        // bb:"130"
    },
    {
        coin_name:"Cosmos",
        coin_short:"ATOM",
        link:"https://coinmarketcap.com/currencies/cosmos/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/3794.png",
        coin_position:"11",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:"",
        // bb:"18"
    },
    {
        coin_name:"Rune",
        coin_short:"RUNE",
        link:"https://coinmarketcap.com/currencies/thorchain/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/4157.png",
        coin_position:"10",
        pairs:"-",
        markets:0,
        week:"",
        date:""
    },
    {
        coin_name:"Chiliz",
        coin_short:"CHZ",
        link:"https://coinmarketcap.com/currencies/chiliz/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/4066.png",
        coin_position:"10",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:""
    },
    {
        coin_name:"Flow",
        coin_short:"FLOW",
        link:"https://coinmarketcap.com/currencies/flow",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/4558.png",
        coin_position:"10",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:""
    },
    {
        coin_name:"Monero",
        coin_short:"XMR",
        link:"https://coinmarketcap.com/currencies/monero/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/328.png",
        coin_position:"2",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:"",
        // bb:"115.62"
    },
    {
        coin_name:"Harmony",
        coin_short:"ONE",
        link:"https://www.coingecko.com/en/coins/harmony",
        coin_logo:"https://assets.coingecko.com/coins/images/4344/small/Y88JAze.png",
        coin_position:"20",
        pairs:"BUSD",
        markets:5,
        week:"",
        date:"",
        // bb:"7"
    },
    {
        coin_name:"Fantom",
        coin_short:"FTM",
        link:"https://coinmarketcap.com/currencies/fantom/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/3513.png",
        coin_position:"16",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:"",
        // predictions:{
        //     "2022":"5.20",
        //     "2023":"5.96",
        //     "2024":"7.45",
        //     "2025":"9.32",
        //     "2026":"11.65",
        //     "2027":"14.56",
        //     "2028":"18.20",
        //     "2029":"22.75",
        //     "2030":"28.44",
        // },
        // bb:"1.10"
    },
    {
        coin_name:"Nano",
        coin_short:"NANO",
        link:"https://coinmarketcap.com/currencies/nano/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/1567.png",
        coin_position:"10",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:"",
        // bb:"2.44"
    },
    {
        coin_name:"Helium",
        coin_short:"HNT",
        link:"https://coinmarketcap.com/currencies/helium/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/5665.png",
        coin_position:"2",
        pairs:"USDT",
        markets:1,
        week:"",
        date:"",
        // bb:"16.50"
    },
    {
        coin_name:"Linear",
        coin_short:"LINA",
        link:"https://coinmarketcap.com/currencies/linear/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/7102.png",
        coin_position:"9",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:""
    },
    // {
    //     coin_name:"FTX Token",
    //     coin_short:"FTX",
    //     link:"https://coinmarketcap.com/currencies/ftx-token/",
    //     coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/4195.png",
    //     coin_position:"11",
    //     pairs:"BUSD",
    //     markets:1,
    //     week:"",
    //     date:"",
    //     bb:"26.50"
    // },
    {
        coin_name:"Binance Coin",
        coin_short:"BNB",
        link:"https://coinmarketcap.com/currencies/binance-coin/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png",
        coin_position:"6",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:""
    },
    {
        coin_name:"Zilliqa",
        coin_short:"ZIL",
        link:"https://coinmarketcap.com/currencies/zilliqa/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/2469.png",
        coin_position:"21",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:""
    },
    {
        coin_name:"Uniswap",
        coin_short:"UNI",
        link:"https://coinmarketcap.com/currencies/uniswap/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/7083.png",
        coin_position:"18",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:""
    },
    {
        coin_name:"XRP",
        coin_short:"XRP",
        link:"https://coinmarketcap.com/currencies/xrp/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/52.png",
        coin_position:"24",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:""
    },
    {
        coin_name:"SushiSwap",
        coin_short:"SUSHI",
        link:"https://coinmarketcap.com/currencies/sushiswap/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/6758.png",
        coin_position:"18",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:""
    },
    {
        coin_name:"Star Atlas",
        coin_short:"ATLAS",
        link:"https://coinmarketcap.com/currencies/star-atlas/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/11212.png",
        coin_position:"9",
        pairs:"BUSD",
        markets:5,
        week:"",
        date:""
    },
    {
        coin_name:"Star Atlas DAO",
        coin_short:"POLIS",
        link:"https://coinmarketcap.com/currencies/star-atlas-polis/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/11213.png",
        coin_position:"9",
        pairs:"BUSD",
        markets:5,
        week:"",
        date:""
    },
    {
        coin_name:"dYdX",
        coin_short:"DYDX",
        link:"https://coinmarketcap.com/currencies/dydx/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/11156.png",
        coin_position:"11",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:"",
        // bb:"7.12"
    },   
    {
        coin_name:"RAMP",
        coin_short:"RAMP",
        link:"https://www.coingecko.com/en/coins/ramp",
        coin_logo:"https://assets.coingecko.com/coins/images/12837/small/RAMP-Logo-v2-1000pxsq.png",
        coin_position:"20",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:""
    },
    {
        coin_name:"Quant",
        coin_short:"QNT",
        link:"https://coinmarketcap.com/currencies/quant/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/3155.png",
        coin_position:"11",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:"",
        // bb:"120"
    },
    {
        coin_name:"Enjin",
        coin_short:"ENJ",
        link:"https://coinmarketcap.com/currencies/enjin-coin/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/2130.png",
        coin_position:"7",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:"",
        // bb:"1.24"
    },
    {
        coin_name:"Tezos",
        coin_short:"XTZ",
        link:"https://coinmarketcap.com/currencies/tezos/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/2011.png",
        coin_position:"11",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:"",
        // bb:"2.76"
    },
    {
        coin_name:"Fetch.ai",
        coin_short:"FET",
        link:"https://coinmarketcap.com/currencies/fetch/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/3773.png",
        coin_position:"13",
        pairs:"USDT",
        markets:1,
        week:"",
        date:"",
        // bb:"0.32"
    },
    {
        coin_name:"VeChain",
        coin_short:"VET",
        link:"https://coinmarketcap.com/currencies/vechain/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/3077.png",
        coin_position:"5",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:"",
        // bb:"0.053"
    }, 
    {
        coin_name:"Raydium",
        coin_short:"RAY",
        link:"https://coinmarketcap.com/currencies/raydium/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/8526.png",
        coin_position:"9",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:"",
        // bb:"4.14"
    },
    {
        coin_name:"Audius",
        coin_short:"AUDIO",
        link:"https://coinmarketcap.com/currencies/audius/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/7455.png",
        coin_position:"9",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:"",
        // bb:"1"
    },
    {
        coin_name:"Crypto.com Coin",
        coin_short:"CRO",
        link:"https://coinmarketcap.com/currencies/crypto-com-coin/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/3635.png",
        coin_position:"20",
        pairs:"USD",
        markets:5,
        week:"",
        date:"",
        // bb:"0.23"
    },
    {
        coin_name:"Kusama",
        coin_short:"KSM",
        link:"https://coinmarketcap.com/currencies/kusama/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/5034.png",
        coin_position:"11",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:"",
        // bb:"166"
    },
    // {
    //     coin_name:"ECOMI",
    //     coin_short:"OMI",
    //     link:"https://coinmarketcap.com/currencies/ecomi/",
    //     coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/6432.png",
    //     coin_position:"11",
    //     pairs:"",
    //     markets:5,
    //     week:"",
    //     date:"",
    //     // bb:"0.034"
    // },
    {
        coin_name:"XYO",
        coin_short:"XYO",
        link:"https://coinmarketcap.com/currencies/xyo/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/2765.png",
        coin_position:"11",
        pairs:"",
        markets:5,
        week:"",
        date:"",
        // bb:"0.020"
    },
    {
        coin_name:"Synthetix",
        coin_short:"SNX",
        link:"https://coinmarketcap.com/currencies/synthetix-network-token/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/2586.png",
        coin_position:"11",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:"",
        // bb:"4"
    },
    {
        coin_name:"Orion Protocol",
        coin_short:"ORN",
        link:"https://coinmarketcap.com/currencies/orion-protocol/markets/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/5631.png",
        coin_position:"11",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:""
    },
    {
        coin_name:"Eden",
        coin_short:"EDEN",
        link:"https://coinmarketcap.com/currencies/archer-dao-governance-token/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/7750.png",
        coin_position:"11",
        pairs:"BUSD",
        markets:5,
        week:"",
        date:""
    },
    {
        coin_name:"Alchemix",
        coin_short:"ALCX",
        link:"https://coinmarketcap.com/currencies/alchemix/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/8613.png",
        coin_position:"10",
        pairs:"-",
        markets:5,
        week:"",
        date:""
    },
    {
        coin_name:"Alpha Finance Lab",
        coin_short:"ALPHA",
        link:"https://coinmarketcap.com/currencies/alpha-finance-lab/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/7232.png",
        coin_position:"11",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:""
    },

    // {
    //     coin_name:"ARMOR",
    //     coin_short:"ARMOR",
    //     link:"https://coinmarketcap.com/currencies/armor/",
    //     coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/8309.png",
    //     coin_position:"12",
    //     pairs:"-",
    //     markets:5,
    //     week:"",
    //     date:""
    // },
    {
        coin_name:"Liquity",
        coin_short:"LQTY",
        link:"https://coinmarketcap.com/currencies/liquity/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/7429.png",
        coin_position:"13",
        pairs:"-",
        markets:0,
        week:"",
        date:""
    },
    {
        coin_name:"Ren",
        coin_short:"REN",
        link:"https://coinmarketcap.com/currencies/ren/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/2539.png",
        coin_position:"13",
        pairs:"USDT",
        markets:1,
        week:"",
        date:""
    },
    {
        coin_name:"Alchemy Pay",
        coin_short:"ACH",
        link:"https://coinmarketcap.com/currencies/alchemy-pay/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/6958.png",
        coin_position:"15",
        pairs:"-",
        markets:5,
        week:"",
        date:""
    },
    {
        coin_name:"XDC Network",
        coin_short:"XDC",
        link:"https://coinmarketcap.com/currencies/xinfin/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/2634.png",
        coin_position:"9",
        pairs:"",
        markets:4,
        week:"",
        date:""
    },
    {
        coin_name:"Chia Network",
        coin_short:"XCH",
        link:"https://coinmarketcap.com/currencies/chia-network/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/9258.png",
        coin_position:"14",
        pairs:"",
        markets:5,
        week:"",
        date:""
    },
    {
        coin_name:"iExec RLC",
        coin_short:"RLC",
        link:"https://coinmarketcap.com/currencies/rlc/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/1637.png",
        coin_position:"15",
        pairs:"USDT",
        markets:1,
        week:"",
        date:""
    },
    {
        coin_name:"Hedera Hashgraph",
        coin_short:"HBAR",
        link:"https://www.coingecko.com/en/coins/hedera-hashgraph",
        coin_logo:"https://assets.coingecko.com/coins/images/3688/small/mqTDGK7Q.png",
        coin_position:"20",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:""
    },
    {
        coin_name:"Bluzelle",
        coin_short:"BLZ",
        link:"https://coinmarketcap.com/currencies/bluzelle/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/2505.png",
        coin_position:"20",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:""
    },
    {
        coin_name:"Stellar",
        coin_short:"XLM",
        link:"https://coinmarketcap.com/currencies/stellar/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/512.png",
        coin_position:"9",
        pairs:"BUSD",
        markets:5,
        week:"",
        date:"",
        // bb:"0.16"
    },
    {
        coin_name:"EOS",
        coin_short:"EOS",
        link:"https://coinmarketcap.com/currencies/eos/",
        coin_logo:"https://s2.coinmarketcap.com/static/img/coins/64x64/1765.png",
        coin_position:"23",
        pairs:"BUSD",
        markets:1,
        week:"",
        date:""
    }

];
export default allCoins;