import React from 'react';
import white from './white.png';
import coinmarketcap from './apple-touch-icon.png';
import gecko from './favicon-96x96.png';
import ReactTooltip from 'react-tooltip';

const Coins = (props) => {
    var coinProps = props?props.coinProps:false;
    var link = coinProps.link;
    var market = <div className="coinIsBi">x</div>;
    if (coinProps.markets === 1){
        market = <div className="coinIsBi "><img src={white} alt={coinProps.coin_name}/></div>;
    }
    if (coinProps.markets === 2){
        market = <div className="coinIsBi"><img src="https://s2.coinmarketcap.com/static/img/coins/64x64/4195.png" alt={coinProps.coin_name}/></div>;
    }
    if (coinProps.markets === 3){
        market = <div className="coinIsBi"><img src="https://s2.coinmarketcap.com/static/img/coins/64x64/8526.png" alt={coinProps.coin_name}/></div>;
    }
    if (coinProps.markets === 4){
        market = <div className="coinIsBi"><img src="https://s2.coinmarketcap.com/static/img/exchanges/64x64/311.png" alt={coinProps.coin_name}/></div>;
    }
    if (coinProps.markets === 5){
        market = <div className="coinIsBi"><img src="https://s2.coinmarketcap.com/static/img/exchanges/64x64/302.png" alt={coinProps.coin_name}/></div>;
    }
    // componentDidMount() {
    //     $('[data-toggle="tooltip"]').tooltip();
    // }
    // componentDidUpdate() {
    //     $('[data-toggle="tooltip"]').tooltip();
    // }

    return (
        <button key={coinProps.coin_logo} className="coin rounded border-0">
            <img src={coinProps.coin_logo} alt={coinProps.coin_name}/>
            <h1 className="coiName d-none d-lg-block">{coinProps.coin_name}</h1>
            <h2 className="coinShort">{coinProps.coin_short}</h2>
            <h2 className="coinPos">{(parseFloat(props.index))+1}</h2>
            <h2 className="coinMarket d-none d-lg-block">{coinProps.pairs}</h2>
            {market}
            {coinProps.predictions ? 
                // <div key={key + 1} >&nbsp;{key}:{coinProps.predictions[key]}&nbsp;|&nbsp;</div>
                <span>
                    <a className="btn btn-secondary bbu mt-0 ms-3" data-for={coinProps.coin_short} data-tip={
                        Object.keys(coinProps.predictions).map((key) => (
                               "<br>"+key+": "+coinProps.predictions[key]+"$"
                            )
                        )
                    }
                    >PP
                </a>
                <ReactTooltip
                id={coinProps.coin_short}
                place="top"
                type="success"
                effect="solid"
                multiline={true}/>
                </span>
            :''}
            {coinProps.bb?
                <span className="btn btn-success mt-0 ms-3 bbu">BBU: {coinProps.bb}$</span>
            :''}
            <a className="ms-3" href={link.toString()}>
                <img src={link.toString().indexOf("coingecko") > -1 ? gecko : coinmarketcap} alt={coinProps.coin_name}/>
            </a>
        </button>
    );
}

export default Coins;
