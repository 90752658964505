import logo from "./bnb.png?v=1";
import "./App.css";
import React from "react";
import Axios from "axios";
import allCoins from "./all-coins";
import Coins from "./Coins";
import Trades from "./Trades";
import {formatPrice} from "./Helpers";

class App extends React.Component {
  state = {
    general: {
      coins: {
        coin_name: "",
        coin_short: "",
        coin_logo: "",
        coin_position: "",
        week: "",
        date: "",
      },
      btcPrice: "loading...",
      btcPriceFormated: "loading...",
      walesAsk: [],
      walesBid: [],
      weightedAvgPrice: ""
    },
  };
  updateStateGeneral = (key, value) => {
    const general = { ...this.state.general };
    if (key.indexOf("wales") > -1) {
      general[key].push(value);
    } else {
      general[key] = value;
    }
    this.setState({ general });
  };
  handleDepth(all) {
    var asks = all.asks;
    var bids = all.bids;
    var walesSwimAt = 30;
    for (let index = 0; index < asks.length; index++) {
      const btcCount = asks[index][1];
      const btcPrice = asks[index][0];
      if (parseFloat(btcCount) > walesSwimAt) {
        this.updateStateGeneral("walesAsk", [btcCount, btcPrice]);
      }
    }
    for (let index = 0; index < bids.length; index++) {
      const btcCount = bids[index][1];
      const btcPrice = bids[index][0];
      if (parseFloat(btcCount) > walesSwimAt) {
        this.updateStateGeneral("walesBid", [btcCount, btcPrice]);
      }
    }
  }
  
  // RSI = 100 – 100/ (1 + RS) RS = Average Gain of n days UP  / Average Loss of n days DOWN
  calculateRSI(rsi){
    // console.log(rsi)
    // var real = 100 - 100 / (1 + parseFloat(rsi.priceChangePercent)/);
    // console.log(real);
    // // [
    //   [
    //     1499040000000,      // Open time
    //     "0.01634790",       // Open
    //     "0.80000000",       // High
    //     "0.01575800",       // Low
    //     "0.01577100",       // Close
    //     "148976.11427815",  // Volume
    //     1499644799999,      // Close time
    //     "2434.19055334",    // Quote asset volume
    //     308,                // Number of trades
    //     "1756.87402397",    // Taker buy base asset volume
    //     "28.46694368",      // Taker buy quote asset volume
    //     "17928899.62484339" // Ignore
    //   ]
    // ]
  }
  axiosRequest = (symbol, action) => {
    const $this = this;
    var csymbol = "?symbol=" + symbol;
    if (action === "depth") {
      csymbol = "?symbol=" + symbol + "&limit=5000";
    }
    // if (action === "klines") {
    //   csymbol = "?symbol=" + symbol + "&interval=12h";
    // }
    // /api/v3/klines interval
    var apiLinks = ['https://api.binance.com', 'https://api1.binance.com', 'https://api2.binance.com', 'https://api3.binance.com'];
    var randomApiLink = apiLinks[Math.floor(Math.random()*apiLinks.length)];
    Axios.get(randomApiLink+"/api/v3/" + action + csymbol)
      .then(function (response) {
        let $re = response.data;
        if (action === "ticker/24hr") {
          $this.updateStateGeneral('weightedAvgPrice', $re.weightedAvgPrice);
          var btcCurrent =  parseFloat($re.lastPrice).toFixed(2);
          $this.updateStateGeneral('btcPrice',  btcCurrent);
          $this.updateStateGeneral('btcPriceFormated',  formatPrice(btcCurrent));
        }
        if (action === "depth") {
          $this.handleDepth($re);
        }
        // if (action === "klines") {
        //   $this.calculateRSI($re);
        // }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  handleLink(link) {
        // console.log("link ", link)
        // preventDefault();
        console.log('le')
    }
  refreshValues() {
    if (this) {
      this.axiosRequest("BTCUSDT", "depth");
      this.axiosRequest("BTCUSDT", "ticker/24hr");
      // this.axiosRequest("BTCUSDT", "klines");
    }
  }
  componentDidMount() {
    this.updateStateGeneral("coins", allCoins);
    if (this.state.general.btcPrice === "loading...") {
      this.refreshValues();
    }
  }
  render() {
    return (
      <div className="App">
        <header className="App-header mt-4">
          <img src={logo} className="App-logo" alt="logo" />
       <div className="d-flex flex-row mt-5">
       {this.state.general.walesAsk.length ||
        this.state.general.walesBid.length ? (
          <a href="/" className="coin btn r-2" onClick={this.refreshValues}>
            Refresh all values
          </a>
        ) : (
          ""
        )}
        <a href="/learn" className="coin btn">
            Trading strategy
          </a>
       </div>
        </header>
        <h2 className="mt-4">
          BTC current price is at <br /> {this.state.general.btcPriceFormated}
        </h2>
        <div className="container mnh-300">
          {this.state.general.walesAsk.length ||
          this.state.general.walesBid.length ? (
            <h4 className="mt-4">Binance order book</h4>
          ) : (
            ""
          )}
          <div className="row justify-content-center mt-4">
            {this.state.general.walesAsk.length ? (
              <Trades
                waleProps={this.state.general.walesAsk}
                btcValue={this.state.general.btcPrice}
                tradeType={1}
              />
            ) : (
              ""
            )}
            {this.state.general.walesBid.length ? (
              <Trades
                waleProps={this.state.general.walesBid}
                btcValue={this.state.general.btcPrice}
              />
            ) : (
              ""
            )}
          </div>
          <div className="col-12">
            <p>
            <strong>AutomatEdd says: Trades on BTC/USDT are currently
            {this.state.general.btcPrice < this.state.general.weightedAvgPrice?
              " below "
            :''}
            {this.state.general.btcPrice > this.state.general.weightedAvgPrice?
              " above "
            :''}
            <a target="_blank" rel="noreferrer" href="https://academy.binance.com/en/articles/volume-weighted-average-price-vwap-explained">the weighted average price</a>
            <br className="d-none d-lg-block"/> which probably means we are currently following a daily&nbsp;
            {this.state.general.btcPrice < this.state.general.weightedAvgPrice?
              <u>downward</u>
            :''}
            {this.state.general.btcPrice > this.state.general.weightedAvgPrice?
              <u>upward</u>
            :''}
            &nbsp;trend.
          </strong>
        </p>
          </div>
       
        </div>
        <div className="mb-5">
          <h3>Referrals</h3>
          <ul className="text-start d-inline-block">
            <li><a target="_blank" rel="noreferrer" href="https://accounts.binance.com/en/register?ref=ZLTY021Z">Binance referral, 10% off your commission</a></li>
            <li><a target="_blank" rel="noreferrer" href="https://www.gate.io/signup/6540017">Gate.io, up to 20% off your commission</a></li>
            <li><a target="_blank" rel="noreferrer" href="https://www.kucoin.com/r/rf/7P48cc">Kucoin</a></li>
          </ul>
        </div>
        
        <h3>Coins worthy of mentioning</h3>
        {/* <p>01.01.2022, <strong>3 NEW COINS ADDED:</strong> no: 3, 6, 14</p> */}
        {/* <p>Removed ETC because of it's location. Reordered all coins for Q1 2022</p> */}
        <p>You won't find any financial advice here! This is just the list of coins I like. <strong>Do your own research</strong>!</p>
        <p>
          Note: these are all solid, verified, undervalued projects that should
          last at least for the next 10 years and make great milking cows
        </p>
        {/* <h4>Added new values:</h4> */}
        {/* <p><span className="btn btn-secondary bbu mt-0 ms-3">PP</span> - average price predictions for the next years.</p> */}
        {/* <p><span className="btn btn-success mt-0 ms-3 bbu">BBU: 26$</span> - Best buy at or under the mentioned price.</p> */}
        <p>&nbsp;</p>
        <ul className="week">
          {Object.keys(this.state.general.coins).map((key) => (
            <Coins
              handleLink = {this.handleLink}
              key={key + 1}
              index={key}
              coinProps={this.state.general.coins[key]}
            />
          ))}
        </ul>
        <p>Next altcoin season is expected to be in September 2023</p>
        <p>I'll keep adding!</p>
      </div>
    );
  }
}

export default App;
