import React from 'react'
import Wales from "./Wales";

const Trades = props => {
    var trades = props.waleProps;
    var tradeText = props.tradeType ? 'sells' : "buys";
    return (
        <div className="col-lg-4">
            <h5>Large {tradeText}</h5>
            <ul className="list-unstyled">
            {Object.keys(trades).map((key) => (
                <Wales
                key={key + 1}
                index={key}
                waleProps={trades[key]}
                btcValue={props.btcValue}
                />
            ))}
            </ul>
        </div>
    )
}

export default Trades
