import React from 'react';
import {formatPrice} from "./Helpers";

const Wales = (props) => {
    const waleBtc = parseFloat(props.waleProps[0]).toFixed(2);
    const walePrice = formatPrice(parseFloat(props.waleProps[1]).toFixed(0));
    const btcValue = formatPrice((parseFloat(props.btcValue)*waleBtc));
    return (
        <li>{waleBtc}฿ - ({btcValue}) at {walePrice}</li>
    )
}

export default Wales
